@import "tailwindcss/base";
@import "css/index.scss";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.oh {
  overflow: hidden;
}

hr {
  border-color: #160638;
  transform: scaleY(0.5);
}

// .featured .flickity-viewport {
//   overflow: visible;
// }

::selection {
  background: #d6a249;
  color: white;
}

.col2 {
  @media (min-width: 700px) {
    column-count: 2;
    column-gap: 40px;
  }
}

@keyframes price {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.price-open {
  animation: price 0.3s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}
