main {
  overflow-x: hidden !important;
}

.footer-text {
  p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.375;
  }
}

html,
body {
  font-family: "work-sans", sans-serif;
  color: #313131;
}

a {
  text-decoration: none !important;
}

.wrapper {
  padding-right: 200px;
  padding-left: 200px;
  max-width: 1600px;
  margin: auto;
  @media (max-width: 1200px) {
    padding-right: 100px;
    padding-left: 100px;
  }
  @media (max-width: 800px) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  button {
    font-size: 14px !important;
  }
}

h1,
.h1 {
  font-family: Cormorant-Garamond, serif;
  font-size: responsive 50px 102px;
  line-height: responsive 54px 98px;
}

div {
  outline: none !important;
}

h2,
.h2 {
  font-family: Cormorant-Garamond, serif;
  font-size: responsive 60px 112px;
  line-height: responsive 62px 112px;
}

h3,
.h3 {
  font-size: responsive 16px 19px;
  line-height: 29px;
}

h4,
.h4 {
  font-family: Cormorant-Garamond, serif;
  font-size: responsive 22px 37px;
  line-height: responsive 24px 39px;
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  font-size: responsive 14px 18px;
  line-height: responsive 16px 29px;
}

.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}

.main-footer a {
  white-space: nowrap;
}

// .province-select {
//   overflow: hidden;
//   .flickity-viewport {
//     overflow: visible;
//   }
// }

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .nav-links {
    padding-top: 0;
  }
  .b-demo {
    background-color: #73ffb5 !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  background-color: #f3f0e8 !important;
  padding-left: 30px !important;
  padding-right: 0px !important;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 40px !important;
    width: auto !important;
  }
  .book-btn {
    height: 30px !important;
    width: 100px !important;
  }
  @media (max-width: 1024px) {
    display: grid;
    height: 60px;
    background-color: #f3f0e8 !important;
    .b-demo {
      background-color: #73ffb47f !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    padding-right: 0 !important;
    padding-left: 15px !important;

    .logo {
      height: 35px !important;
    }
  }
}

.headroom--top {
  filter: none;
  box-shadow: none;
  display: grid;
  height: 100px !important;
  .logo {
    height: 50px !important;
    width: 100% !important;
  }
  @media (max-width: 1023px) {
    height: 70px !important;
    .b-demo {
      margin-top: 20px;
      background-color: #73ffb5 !important;
      padding-left: 30px !important;
      padding-right: 0px !important;
    }
    padding-right: 0px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 45px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
  @media (max-width: 800px) {
    height: 60px !important;
    padding-right: 0px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 40px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
  @media (max-width: 300px) {
    height: 100px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 30px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }

  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: #f3f0e8;
  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
}

.slider-text:not(.is-selected) .slide-text {
  opacity: 0 !important;
}

.flickity-slider {
  .is-selected {
    .slider-text {
      opacity: 100;
      transition-delay: 300ms;
      transition-duration: 300ms;
    }
  }
  .slider-text {
    opacity: 0;
  }
}

.boxed-green {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
  }

  .blank-label {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: white;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #8eba3d;
  }

  input[type="checkbox"]:checked + label {
    background: #8eba3d;
    color: #fff;
  }
}

.boxed-orange {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
  }

  .blank-label {
    display: none;
  }
  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: white;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #e79921;
  }

  input[type="checkbox"]:checked + label {
    background: #e79921;
    color: #fff;
  }
}

.boxed-orange-light {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
  }

  .blank-label {
    display: none;
  }
  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: white;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #eac60c !important;
  }

  input[type="checkbox"]:checked + label {
    background: #eac60c !important;
  }
}

div ul {
  margin-left: 20px !important;
  list-style: none;
}

div li {
  position: relative;
  font-size: responsive 16px 20px;
  margin: 10px;
}

div ul li::before {
  content: "\2022";
  color: #6cac38;
  position: absolute;
  font-size: 25px;
  left: -19px;
  top: -3px;
}

.boxed-purple {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: white;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #6e57a4;
  }

  .blank-label {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    background: #6e57a4;
    color: #fff;
  }
}
input {
  outline: none !important;
}
.hero-header {
  &:after {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(transparent),
      color-stop(80%, rgba(0, 0, 0, 0.7))
    );
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7) 80%);
    bottom: 0;
    content: "";
    height: 70%;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
.boxed-green-light {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: white;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #e2e216;
  }

  .blank-label {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    background: #e2e216;
  }
}
input {
  outline: none !important;
}

label {
  font-size: resposive 14px 16px;
}

.menu:before {
  transition: all 0.5s ease-in-out;
  content: "";
}

.menu:focus {
  pointer-events: none;
}

/* If :focus detected the dropdown menu is displayed by making it visible */
.menu:focus .menu-dropdown {
  opacity: 1;
  visibility: visible;
  position: relative;
  margin-bottom: 40px;
  transition: all 0.5s ease-in-out;
}

/* style for the dropdown box */
.menu-dropdown {
  transition: all 0.5s ease-in-out;

  margin-left: 40px;
  width: auto;
  pointer-events: auto;
  position: absolute;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: visibility 1s;
}

/* style the links in the dropdown */
.menu-dropdown a {
  background: transparent;
  border: none;
  outline: 0;
  display: block;
  text-decoration: none;
}

.services {
  display: flex;
  span::before {
    margin-right: 10px;
    content: "+";
  }
}
.services:focus {
  span::before {
    content: "-";
  }
}

summary {
  direction: rtl;
  text-transform: uppercase !important;
  padding-top: 20px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: responsive 18px 48px;
  line-height: responsive 18px 48px;
  font-weight: 300;
}

details[open] {
  .accord-arrows::before {
    content: "Read less";
    transition: all 0.5s ease-out;
  }
  padding-bottom: 20px;
}

details[open] summary {
  display: none !important;
  transition: all 0.5s ease-out;
  -webkit-appearance: none;
  margin-bottom: 20px;
}

.accord-arrows::before {
  transition: all 0.5s ease-out;
  content: "Read more";
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}
